<template>
    <div>
        <div>
            <div class="exa-head">
                <div class="title"><i class="el-icon-edit-outline" style="color: #1ea0fa;"></i> 新建绩效考核</div>
                <div class="back"><el-button style="color: #1ea0fa;"
                        @click="$router.push('/perform/exa')">返回</el-button></div>
            </div>

            <div class="changeBox">
                <el-divider>请输入考核名称</el-divider>
                <el-input v-model="title"></el-input>

                <el-divider>请选择创建方式</el-divider>
                <div class="new" :class="{ 'active': active == 1 }" @click="change(1)">创建新考核</div>
                <div class="use" :class="{ 'active': active == 2 }" @click="change(2)">使用模板创建</div>

                <el-button type="primary" @click="goPage">确定</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0,

            title: '',
        }
    },
    mounted() {

    },
    methods: {
        change(i) {
            this.active = i
        },
        goPage() {
            if (this.title) {
                if (this.active == 2) {
                    this.$router.push({ path: '/perform/exaCre', query: { tit: this.title } })
                } else {
                    this.$router.push({ path: '/perform/exaEdit', query: { tit: this.title } })
                }
            } else {
                this.$message.warning('请输入考核名称');
                return
            }
        }
    },
}
</script>
<style lang="less" scoped>
.exa-head {
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.changeBox {
    width: 800px;
    background: #fff;
    margin: 0 auto;
    padding: 30px 160px 20px;
    text-align: center;

    .new,
    .use {
        border: 1px solid #dcdfe6;
        padding: 15px 0px;
        margin: 10px auto;
        width: 60%;
        cursor: default;
        color: #333;

        &.active {
            border: 2px solid #409eff;
            background: #eff9ff;
            color: #53a3ff;
        }
    }

    .new {
        margin: 30px auto 10px;
    }

    .el-button {
        width: 60%;
        margin: 30px auto;
    }
}
</style>